<template>

  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <user-info-links-comp></user-info-links-comp>
        <div class="board_list">
          <div class="user_info_section">

            <div class="u_left_section">
              <div class="u_info">
                <div class="u_info_title">기본정보</div>
                <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;box-sizing: border-box;padding: 10px">
                  <div style="width: 48%">
                    <img src="../../assets/images/item/oneplay/item_shop_icon_01.png" alt="" style="width: 80%">
                  </div>
                  <table class="table" style="">

                    <tr>
                      <td style="width: 40%">레벨</td>
                      <td class="text-left">{{$store.state.userInfo.rank.rankName}}</td>
                    </tr>
                    <tr>
                      <td>닉네임</td>
                      <td class="text-left">{{$store.state.userInfo.nickname}}</td>
                    </tr>
                    <tr>
                      <td>보유 코인</td>
                      <td class="text-left">{{$store.state.userInfo.cash|comma}}</td>
                    </tr>
                    <tr>
                      <td>보유 BP</td>
                      <td class="text-left">{{$store.state.userInfo.userPoint|comma}}</td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>

            <div class="u_right_section">
              <div class="u_info">
                <div class="u_info_title">로그인 정보</div>
                <table class="table" style="">
                  <tr v-for="item in loginHistoryList">
                    <td style="width: 40%">{{item.createTime|datef('MM/DD HH:mm')}}</td>
                    <td class="">{{item.loginIp}}</td>
                  </tr>

                </table>
                <!--페이지-->
                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"
                            v-if="loginHistoryList.length > 0"></pagination>
              </div>

            </div>

          </div>
          <h4 class="title">참여내역 통계</h4>
          <div class="bet_statistic">
            <table class="table" style="">
              <tr>
                <th>폴더수</th>
                <th>참여건수</th>
                <th>당첨건수</th>
                <th>당첨확율</th>
                <th>참여BP</th>
                <th>당첨BP</th>
              </tr>
              <tr v-for="(item,index) in betStatistic" :key="index">
                <td align="center">{{item.tattrName}}&nbsp;폴더</td>
                <td>{{item.tcount}}&nbsp;건</td>
                <td>{{item.winCount}}&nbsp;건</td>
                <td>
                  {{item.winningProbability}}%
                </td>
                <td>{{item.tamount|comma}}&nbsp;BP</td>
                <td>{{item.twinAmount|comma}}&nbsp;BP</td>

              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>

  </div>

</template>

<script>
  import RightBarComp from "../../components/RightBarComp";
  import SubTitle from "../../components/SubTitle";
  import {changePasswd, getBetStatistic, getLoginHistory, referrerPointLog} from "../../network/userRequest";
  import LeftBarComp from "../../components/LeftBarComp";
  import Pagination from "../../components/pagenation/Pagination";
  import FootComp from "../../components/FootComp";
  import TopbarComp from "../../components/TopbarComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import sportsConst from "../../common/sportsConst";
  import UserInfoLinksComp from "../../components/links/UserInfoLinksComp";

  export default {
    name: "UserInfo",
    components: {
      UserInfoLinksComp,
      SportsLeftBarComp,
      RightBarBannerComp,
      TopbarComp, FootComp, Pagination, LeftBarComp, SubTitle, RightBarComp},
    data() {
      return {
        loginHistoryList: [],
        betStatistic:[],
        userInfo: {rank: {}, referreCode: {}, myReferrer: {}},
        user: {oldPasswd: '', passwd: '', extractPasswd: ''},
        pageNum: 1,
        pageSize: 5,
        total: 1,
        orderBy: null,
        search: {},
        sportsConst,
      }
    },
    methods: {
      changePwd() {
        changePasswd(this.user).then(res => {
          if (res.data.success) {
            this.$swal({
              title: '비밀번호 변경성공',
              type: 'success',
              showCancelButton: false,
              showConfirmButton: true
            })
          } else {
            this.$swal({
              title: res.data.msg,
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
          }
        })
      },
      getLoginHistory(){
        getLoginHistory(this.pageNum,this.pageSize).then(res=>{
          this.loginHistoryList = res.data.data;
          this.total =res.data.total

        })
      },
      getBetStatisc(){
        getBetStatistic(this.search).then(res=>{
          this.betStatistic = res.data.data;
        })
      },

      //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
      pageChange(page) {
        this.pageNum = page
        this.getLoginHistory()
      },
    },
    created() {
      if (!this.$store.state.isLogin) {
        this.$swal({
          title: '로그인이 필요한 서비스 입니다.',
          type: 'warning',
          showCancelButton: false,
          showConfirmButton: true
        })
        this.$router.push('/login')
        return false;
      }
      this.getLoginHistory()
      this.getBetStatisc()
    }
  }
</script>

<style scoped>

  .user_info_section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
  .user_info_section .u_left_section{
    width: 49%;
    box-sizing: border-box;
    padding: 20px;
  }

  .user_info_section .u_info{
    border: 1px solid #0c0e0e;
    background-color: #2e2e2e;
  }
  .user_info_section .u_info .u_info_title{
    width: 100%;
    height: 38px;
    line-height: 38px;
    background-color: #403f3f;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    border-bottom: 1px solid #0c0e0e;
  }
  .u_info table td{
    border-bottom: 1px solid#3b3b3b!important;
  }
  .user_info_section .u_right_section{
    width: 49%;
    box-sizing: border-box;
    padding: 20px;
  }

  @media screen and (max-width: 1024px) {
    input{
      width: 100%;
    }
    .user_info_section .u_left_section{
      width: 100% !important;
    }
    .user_info_section .u_right_section{
      width: 100% !important;
    }
  }

</style>